<template>
  <section>
    <div class="mt-8">
      <h2 class="text-center text-body-4 text-4xl mb-4">List vlastnictví</h2>
    </div>

    <div class="max-w-4xl mt-4 mx-auto mb-3 px-4 font-semibold text-lg">
      List vlastnictví (zkráceně LV) je veřejná listina se soupisem nemovitostí,
      které v daném katastrálním území vlastní konkrétní vlastník či
      spoluvlastník. LV je tedy doklad prokazující vlastnictví. Aktuální podobu
      listů vlastnictví pak můžete zhlédnout přímo v aplikaci MakléřeNechci.
    </div>

    <div class="max-w-4xl mt-4 mx-auto mb-3 px-4 font-semibold text-lg">
      V případě prodeje bytu je nutné mít připraven nejen list vlastnictví bytu
      ale i domu, ve kterém se nachází. V obou případech je vydává katastrální
      úřad daného území nebo pobočky Czech POINTU, kde zaplatíte správní
      poplatek.
    </div>

    <div class="max-w-4xl mt-4 mx-auto mb-3 px-4 font-semibold text-lg">
      Platnost listu vlastnictví začíná s datem vydání, přičemž je zde uveden na
      sekundu přesný čas. Jednotlivé listy jsou označeny také pořadovým číslem
      daného katastrálního území.
    </div>

    <h3 class="text-center text-body-4 text-3xl mb-4 pt-8">
      Rychlý prodej investorovi
    </h3>

    <div class="max-w-4xl mt-4 mx-auto mb-3 px-4 font-semibold text-lg">
      Aplikace MakléřeNechci spolupracuje s mnoha investory, kteří nemovitosti
      nakupují a následně sami distribuují. Celý proces funguje následovně:
    </div>

    <div class="max-w-4xl mt-4 mx-auto mb-3 px-4 font-semibold">
      <li>Vyberete si datum prohlídky nemovitosti.</li>
      <li>
        Zástupce investora vás navštíví, nemovitost prohlédne, zjistí všechny
        potřebné informace a zdokumentuje prostory.
      </li>
      <li>
        Do 72 hodin od návštěvy na e-mail obdržíte nabídku na odkoupení
        nemovitosti (na zvážení máte 14 dnů).
      </li>
    </div>

    <div class="max-w-4xl mt-4 mx-auto mb-3 px-4 font-semibold text-lg pb-4">
      Pokud s nabídkou nebudete souhlasit, máte možnost navrhnout vlastní
      částku. Pak už vše záleží na dohodě s investorem.
    </div>
  </section>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
};
</script>
